* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* html,
body,
#root {
  height: 100%;
} */

#root {
  min-height: 100vh;
}

body {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

@media (max-width: 570px) {
  .logo {
    width: 100%;
  }
}
